<template>
  <div class="main-container"> 
    <img :src="require('../assets/bg1.jpg')" class="bg">

    <div class="wrapper">
      <div id="form">
        <div class="logo">
          <img :src="require('../assets/logo.png')" >
        </div>
        
        <h4 class="text-center">{{ $t('setProfile.label') }}</h4>

        <div class="alert alert-danger" v-if="errors.profile.show">
          {{ errors.profile.msg }}
        </div>

        <form v-on:submit.prevent="submitProfile" >
            <div class="form-group">
                <label for="firstName">{{ $t('setProfile.firstName') }}:</label>
                <input type="text" class="form-control" id="firstName" v-model="profile.firstName">
            </div>
            <div class="form-group">
                <label for="lastName">{{ $t('setProfile.lastName') }}:</label>
                <input type="text" class="form-control" id="lastName" placeholder="" v-model="profile.lastName" >
            </div>
            <div class="form-group">
                <button class="btn btn-main" id="saveBtn">{{ $t('login.submit') }}</button>
            </div>

        </form>

      </div>
    </div>
    
  </div>
</template>

<script>

  import qs from 'querystring'

  export default {
    name: 'SetupProfile',
    components: {},
    data: function() {
      return  {
        profile: {
          firstName: "",
          lastName: "",
        },
        errors: {
          profile: {
            show:false,
            msg: ""
          }
        }
      }
    },
    computed: {
      init() {
        return this.$store.state.init;
      },
      show() {
        return this.$store.state.show;
      },
      list() {
        return this.$store.state.list;
      },
    },
    methods: {
    

      checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
          .then((response) => {

              console.log(response)
            }).catch((err) => {
                
              console.log(err.response.data)     

              if(err.response.data.msgCode == '00001') {
                window.location.href = "/#/login";
              }  
            })
      },

      submitProfile: function(){

       if(localStorage.getItem("lang") == 'en' || localStorage.getItem("lang") == null || localStorage.getItem("lang") == '' || localStorage.getItem("lang") == undefined) {

        if(this.profile.firstName == "" || this.profile.firstName == null || this.profile.firstName == undefined)  {
          this.errors.profile.show = true;
          this.errors.profile.msg = "First name is required";
          return false;
        }

        if(this.profile.lastName == "" || this.profile.lastName == null || this.profile.lastName == undefined)  {
          this.errors.profile.show = true;
          this.errors.profile.msg = "Last name is required";
          return false;
        } 


      this.errors.profile.show = false;
        const params = {
          firstName: this.profile.firstName,
          lastName: this.profile.lastName
        }

        const data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');
   

        const options = {
          method: 'POST',
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            'blox-authz': localStorage.getItem("tokenId"),
          }, 
          data,
          url: '/cx/submitname',
        };

        this.axios(options)
          .then((response) => {
            window.location.href = "/#/setup-wallet"; 
          }).catch((err) => {

            if (err.response.data) { 
              this.errors.profile.show = true;
              if(err.response.data.fieldErrors.length > 0) {
                err.response.data.fieldErrors.forEach((error) => {
                  switch (error.field) {
                    default:
                      his.errors.profile[error.field] = error.defaultMessage;
                    break;
                  }
                }); 
                this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage;

              }  else {
                  this.errors.profile.msg = err.response.data.msgText;
            }
          } 
        })
      } else if(localStorage.getItem("lang") == 'kr') {
        if(this.profile.firstName == "" || this.profile.firstName == null || this.profile.firstName == undefined)  {
          this.errors.profile.show = true;
          this.errors.profile.msg = "이름이 필요합니다";
          return false;
        }

        if(this.profile.lastName == "" || this.profile.lastName == null || this.profile.lastName == undefined)  {
          this.errors.profile.show = true;
          this.errors.profile.msg = "성을 입력해야 합니다";
          return false;
        } 


      this.errors.profile.show = false;
        const params = {
          firstName: this.profile.firstName,
          lastName: this.profile.lastName
        }

        const data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');
   

        const options = {
          method: 'POST',
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            'blox-authz': localStorage.getItem("tokenId"),
          }, 
          data,
          url: '/cx/submitname',
        };

        this.axios(options)
          .then((response) => {
            window.location.href = "/#/setup-wallet"; 
          }).catch((err) => {

            if (err.response.data) { 
              this.errors.profile.show = true;
              if(err.response.data.fieldErrors.length > 0) {
                err.response.data.fieldErrors.forEach((error) => {
                  switch (error.field) {
                    default:
                      his.errors.profile[error.field] = error.defaultMessage;
                    break;
                  }
                }); 
                this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage;

              }  else {
                  this.errors.profile.msg = err.response.data.msgText;
            }
          } 
        })
      }
      }

    },

    beforeMount(){

      this.checkSession();
      
    }
  }
</script>

<style scoped lang="scss">
  .main-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding:1em;
  }
  .logo {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    img{
      width: 50px;  
    }
    
  }

  .wrapper { 
    background: linear-gradient(to right, #020141, #0f0611);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2em;
     color: #FFF;
  }


  /*med*/
  @media(min-width: 40em) {
    
  }
  /*large*/
  @media(min-width: 64em) {

   
  }
  /*xl*/
  @media(min-width: 87.5em) {
    
  }


  /*sm*/
  @media(max-width: 39.9375em) {
    .wrapper {
      padding: 1em !important;
      h4 {
        font-size: 1.25em;
      }

      .email-or-mobile { 

        .btn {
          font-size: 1em;
        }
      }
    }

    button, input {
      font-size: 85%;
    }
  }
  /*med*/
  @media(max-width: 63.9375em) {

     #app {
      
      padding: 2em;
      font-size: 85%;

    }
  }

  /*large*/
  @media(max-width: 87.4375em) {
    
  }

  @media(max-width: 768px) {
 
  }
</style>



